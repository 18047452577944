const data = [
	{
		id: "fm-theme-switcher",
		name: "fm-theme-switcher",
		title: "Social Media Dashboard",
		link: "https://fm-theme-switcher-anna.netlify.app/",
		source: "./images/projects/social.jpeg",
		type: "image/jpeg",
		img: "./images/projects/social.jpeg",
		tags: ["react"],
		repo: "https://github.com/annacsillaxs/FM_social_media_dashboard",
		desc: "Simple Social Media Dashboard React app with dark and light mode toggle function. Static data. Design from Frontend Mentor Social Media Dashboard Challenge.",
		links: ["https://www.frontendmentor.io/challenges/social-media-dashboard-with-theme-switcher-6oY8ozp_H"],
		href: ["Frontend Mentor"],
	},
	{
		id: "clock",
		name: "clock",
		featured: false,
		title: "Themed Clock App",
		link: "https://js-clock-theme.netlify.app/",
		source: "./images/projects/clock.jpg",
		type: "image/jpg",
		img: "./images/projects/clock.jpg",
		tags: ["html", "css", "js"],
		repo: "https://github.com/annacsillaxs/js-clock-theme",
		desc: "Clock App with theme switcher function built with JavaScript. Idea based on Brad Traversy's Theme Clock Project from 50 Projects in 50 Days Udemy Course.",
	},
	{
		id: "iptracker",
		featured: true,
		name: "IPTracker",
		title: "IP Address Tracker App",
		link: "https://ip-tracker-fm-anna.netlify.app/",
		source: "./images/projects/tracker.png",
		type: "image/png",
		img: "./images/projects/tracker.png",
		tags: ["html", "css", "js", "api"],
		repo: "https://github.com/annacsillaxs/FM-IP-Address_tracker",
		desc: "JavaScript App based on an advanced Frontend Mentor Challenge. To get the IP Address locations, I used the IP Geolocation API by IPify. To generate the map, I used LeafletJS. Responsive Layout.",
		links: ["https://leafletjs.com/", "https://geo.ipify.org/", "https://www.frontendmentor.io/challenges/ip-address-tracker-I8-0yYAH0"],
		href: ["LeafletJS", "IP Geolocation API", "Frontend Mentor"],
	},
	{
		id: "form",
		featured: true,
		name: "form_validation",
		title: "Form Validation Component",
		link: "https://fm-form-validation-anna.netlify.app/",
		source: "./images/projects/form.jpeg",
		type: "image/jpeg",
		img: "./images/projects/form.jpeg",
		tags: ["html", "css", "js"],
		repo: "https://github.com/annacsillaxs/FM_form_validation",
		desc: "Intro Component with form validation. I used Regex in order to validate the phone number, the email address and the criteria of the password. Design idea from Frontend Mentor.",
		links: ["https://www.frontendmentor.io/solutions/form-validation-with-some-extra-input-fields-6PZQgQiyK"],
		href: ["Frontend Mentor"],
	},
	{
		id: "herzigs",
		featured: true,
		name: "herzigs",
		title: "Herzigs Website",
		link: "https://herzigs-react-anna.netlify.app/",
		source: "./images/projects/herzigs.jpg",
		type: "image/jpeg",
		img: "./images/projects/herzigs.jpg",
		tags: ["react"],
		repo: "https://github.com/annacsillaxs/herzigs-react",
		desc: "Herzigs Vehicle Repair Website. I've created the design based on their previous site. This is a Single Page React App.",
	},
	{
		id: "greenByte",
		name: "greenByte",
		title: "GreenByte Site",
		link: "https://green-byte-landing.netlify.app/",
		source: "./images/projects/gb.jpg",
		type: "image/jpg",
		img: "./images/projects/gb.jpg",
		tags: ["html", "css"],
		repo: "https://github.com/annacsillaxs/greenbyte",
		desc: "GreenByte freelancer developer landing page and contact information. I've built the page as a freelancer. Responsive layout.",
	},
	{
		id: "relaxer",
		name: "relaxer",
		title: "Relaxer JS App",
		link: "https://js-relaxer-app.netlify.app/",
		source: "./images/projects/relax.jpg",
		type: "image/jpg",
		img: "./images/projects/relax.jpg",
		tags: ["html", "css", "js"],
		repo: "https://github.com/annacsillaxs/js-relaxer-app",
		desc: "Relaxer JavaScript App with CSS animation based on Brad Traversy's 20 Web Projects with Vanilla JS Udemy Course. Follow the animated circle, breath in for 5 sec, hold it for 2.5 sec and breath out for 5 sec.",
	},
	{
		id: "rgb",
		name: "rgb game",
		title: "RGB Guessing Game",
		link: "https://rgba-color-guessing-game.netlify.app/",
		source: "./images/projects/rgba.jpg",
		type: "image/jpg",
		img: "./images/projects/rgba.jpg",
		tags: ["html", "css", "js"],
		repo: "https://github.com/annacsillaxs/ColorGuessingGame",
		desc: "RGB Color Guessing Game built with JavaScript. The quest is a randomly generated RGB color. The player can choose from 6 other colors, one matches with the quest, the other 5 are randomly generated.",
	},
	{
		id: "dadjokes",
		name: "dadjokes api",
		title: "Dadjokes API",
		link: "https://sobaddajokes.netlify.app/",
		source: "./images/projects/dadjokes.jpg",
		type: "image/jpg",
		img: "./images/projects/dadjokes.jpg",
		tags: ["html", "css", "js", "api"],
		repo: "",
		desc: "Every time you ask for a new joke, the app fetches a new one from the API. Idea based on Brad Traversy's Dad Jokes Project from 50 Projects in 50 Days Udemy Course.",
		links: ["https://icanhazdadjoke.com/"],
		href: ["icanhazdadjoke API"],
	},
	{
		id: "infinitescroll",
		name: "infinite scroll",
		title: "Infinite Scroll",
		link: "https://infinite-scroll-blog.netlify.app/",
		source: "./images/projects/infinitescroll.jpg",
		type: "image/jpg",
		img: "./images/projects/infinitescroll.jpg",
		tags: ["html", "css", "js", "api"],
		repo: "https://github.com/annacsillaxs/infinite-scroll-blog",
		desc: "Infinite scroll blog posts app built with JavaScript. The app uses JSON Placeholder API for the blog posts. Highlighted search term in text. Idea based on Brad Traversy's 20 Web Projects with Vanilla JS Udemy Course.",
		links: ["https://jsonplaceholder.typicode.com/"],
		href: ["{JSON} Placeholder API"],
	},
	{
		id: "vertical slider",
		name: "vertical slider",
		title: "Vertical Slider Gallery",
		link: "https://js-vertical-slider-infinite.netlify.app/",
		source: "./images/projects/slider.jpg",
		type: "image/jpg",
		img: "./images/projects/slider.jpg",
		tags: ["html", "css", "js"],
		repo: "https://github.com/annacsillaxs/js-vertical-slider-infinite",
		desc: "Double Vertical Infinite Gallery Slider with CSS animation. Idea based on Brad Traversy's Dad Jokes Project from 50 Projects in 50 Days Udemy Course.",
	},
	{
		id: "dragndrop",
		name: "drag n drop",
		title: "Drag N Drop Puzzle",
		link: "https://js-drag-n-drop-puzzle.netlify.app/",
		source: "./images/projects/dragndrop.jpg",
		type: "image/jpg",
		img: "./images/projects/dragndrop.jpg",
		tags: ["html", "css", "js"],
		repo: "https://github.com/annacsillaxs/js-drag-n-drop-puzzle",
		desc: "You can get a hint which squares are in the right position and which one are not. At the end of the game you can shuffle them again. Idea based on Brad Traversy's Drag N Drop Puzzle Project from 50 Projects in 50 Days Udemy Course.",
	},
	{
		id: "lyrics",
		name: "lyrics search",
		title: "Lyrics Search App",
		link: "https://lyrics-search-js-app.netlify.app/",
		source: "./images/projects/lyrics.jpeg",
		type: "image/jpeg",
		img: "./images/projects/lyrics.jpeg",
		tags: ["html", "css", "js", "api"],
		repo: "https://github.com/annacsillaxs/lyrics-search-js-app",
		desc: "Lyrics Search App with pagination. Lyrics from Lyrics.ovh API. Idea based on Brad Traversy's Search App from 20 Web Projects with Vanilla JS Udemy Course.",
		links: ["https://api.lyrics.ovh/suggest/queens"],
		href: ["Lyrics.ovh API"],
	},
	{
		id: "sunnyside",
		name: "sunnyside",
		title: "Sunnyside Landing Page",
		link: "https://sunnyside-fm-anna.netlify.app/",
		source: "./images/projects/sunnyside.jpeg",
		type: "image/jpeg",
		img: "./images/projects/sunnyside.jpeg",
		tags: ["html", "css"],
		repo: "https://github.com/annacsillaxs/FM_sunnyside_landing_page",
		desc: "Free Simple Responsive Sunnyside Agency Landing Page. Junior Level Challenge from Frontend Mentor without design files. Responsive Layout.",
		links: ["https://www.frontendmentor.io/challenges/sunnyside-agency-landing-page-7yVs3B6ef"],
		href: ["Frontend Mentor"],
	},
	{
		id: "splitter",
		name: "splitter",
		featured: true,
		title: "Tip Splitter React App",
		link: "https://fm-splitter-react-anna.netlify.app/",
		source: "./images/projects/splitter.jpeg",
		type: "image/jpeg",
		img: "./images/projects/splitter.jpeg",
		tags: ["react"],
		repo: "https://github.com/annacsillaxs/FM-splitter-react",
		desc: "Bill Splitter React App. You can enter the bill amount, the tip percentage and the number of people who'd like to share the bill. The tip amount and the total calculated per person. Junior Challenge from Frontend Mentor. Responsive Layout.",
		links: ["https://www.frontendmentor.io/challenges/tip-calculator-app-ugJNGbJUX"],
		href: ["Frontend Mentor"],
	},
	{
		id: "minesweeper",
		name: "minesweeper",
		featured: true,
		title: "Minesweeper Retro Game",
		link: "https://minesweeper-anna.netlify.app/",
		source: "./images/projects/minesweeper.jpg",
		type: "image/jpg",
		img: "./images/projects/minesweeper.jpg",
		tags: ["html", "css", "js"],
		repo: "https://github.com/annacsillaxs/minesweeper",
		desc: "Good old Minesweeper Game. Double click on the icon to start the game. Functionality built with Vanilla JS using recursion.",
		links: ["https://www.youtube.com/watch?v=W0No1JDc6vE&t=2s"],
		href: ["Ania Kubów - Minesweeper"],
	},
	{
		id: "coffeeroasters",
		name: "coffeeroasters",
		featured: true,
		title: "Coffeeroasters landing page",
		link: "https://coffeeroasters-fm-anna.netlify.app/",
		source: "./images/projects/coffeeroasters.png",
		type: "image/png",
		img: "./images/projects/coffeeroasters.png",
		tags: ["html", "css", "js"],
		repo: "https://github.com/annacsillaxs/coffeeroasters",
		desc: "You can choose from different options and create a personalized coffee subscription plan. See your order summary in a modal. Premium Challenge from Frontend Mentor with Figma design files. Responsive Layout.",
		links: ["https://www.frontendmentor.io/challenges/coffeeroasters-subscription-site-5Fc26HVY6"],
		href: ["Frontend Mentor"],
	},
];
export default data;
